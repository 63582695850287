import { HomePage } from "@/components/HomePage";
import * as GQL from "@/gql";
import { fetchCMS } from "@/lib/graphql/api";
import { GetStaticProps } from "next";

interface Props {
  data: {
    homePageQuery: GQL.HomePageQuery;
    posts: GQL.MostRecentNewsAndEventsQuery;
  };
}

export default function Page(props: Props) {
  const { homePageQuery, posts } = props.data;

  return <HomePage homePage={homePageQuery.homePage!} posts={posts}></HomePage>;
}

export const getStaticProps: GetStaticProps<Props, {}> = async (context) => {
  const { locale } = context;

  const homePageQuery = await fetchCMS<GQL.HomePageQuery>(GQL.HomePageDocument, {
    preview: context.preview,
    variables: { locale },
  });

  if (!homePageQuery) {
    return {
      notFound: true,
    };
  }

  const posts = await fetchCMS<GQL.MostRecentNewsAndEventsQuery>(GQL.MostRecentNewsAndEventsDocument, {
    preview: context.preview,
    variables: { locale },
  });

  return {
    props: {
      data: {
        homePageQuery,
        posts,
      },
    },
  };
};
